import { MESUREMENT_ID } from "./constants";

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

type PageViewEvent = {
  event: "pageview";
  page: string;
};

type WalletSigninEvent = {
  event: "wallet_signin";
  wallet_id: string;
};

type PurchaseMOPTEvent = {
  event: "purchase_mopt";
  currency: string;
  value: number;
  transaction_id: string;
  wallet_id: string;
  items: {
    item_name: string;
    quantity: number;
    price: number;
  }[];
};

type Event = PageViewEvent | PurchaseMOPTEvent | WalletSigninEvent;

export const event = (args: Event) => {
  window?.dataLayer.push(args);
};

export const pageView = (url: string) => {
  window.gtag?.("config", MESUREMENT_ID, { page_path: url });
};
