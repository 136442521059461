
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ExternalDataSource": [
      "NotionDataSource"
    ],
    "Node": [
      "Diary",
      "NotionDataSource",
      "User"
    ]
  }
};
      export default result;
    