import {
  ApolloClient,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getSession } from "next-auth/react";
import { useMemo } from "react";
import { API_URL } from "src/config";
import introspection from "src/types/introspection.generated";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject>;

const isServer = typeof window === "undefined";

const httpLink = new HttpLink({
  uri: `${API_URL}/graphql`,
  credentials: "same-origin",
});

const authLink = setContext(async (_, { headers }) => {
  const session = await getSession();
  return {
    headers: {
      ...headers,
      authorization: session?.raw ? `Bearer ${session.raw}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (process.env.NODE_ENV === "production") return;
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const createApolloClient = () => {
  return new ApolloClient({
    ssrMode: isServer,
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      possibleTypes: introspection.possibleTypes,
    }),
  });
};

export const initializeApollo = (initialState?: NormalizedCacheObject) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (isServer) return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const useApollo = (initialState: NormalizedCacheObject) => {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
};
